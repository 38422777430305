<template>
  <LayerPopup :popOpen="params.open" :fixedBtn="true" :fullpopup="true" @popClose="onClose" class="layer"
    title="만 14세 이상 이용">
    <div class="terms-container">
      <div class="view-box">
        <dl class="terms">
          <dt>{{$t('content.signup.popup.AgeInfo.txt01')}}</dt>
        </dl>
      </div>
    </div>
    <div class="btn_group">
      <button type="button" class="btn btn-ghost" @click.prevent="onAgree(false)">미동의</button>
      <button type="button" class="btn btn-basic" @click.prevent="onAgree(true)">동의</button>
    </div>
  </LayerPopup>
</template>
<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  id: 'ageAgreedPopup',
  mixins: [mixinHelperUtils],
  components: {
    LayerPopup: () => import('@/components/base/LayerPopup.vue'),
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
      }),
    },
    allOpen: {
      type: Object,
      default: () => ({
        allOpen: false,
      }),
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onAgree(state) {
      if (state) {
        // true
        this.params.open = false;
        this.$emit('privacyAgeAgree', state);
      } else {
        // false alert
        this.showAlert('미동의시 회원가입 진행이 불가합니다.');
      }
    },
    onClose() {
      this.params.open = false;
      this.allOpen.allOpen = false;
    },
  },
};
</script>

<template>
  <LayerPopup :popOpen="params.open" :fixedBtn="true" :fullpopup="true" @popClose="onClose" class="layer"
    title="개인정보 수집 및 이용">
    <div class="terms-container">
      <div class="view-box">
        <dl class="terms">
          <dd>{{$t('content.signup.popup.PersonalInfoUse.txt02')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt03')}}<br />
          </dd>
          <dt>{{$t('content.signup.popup.PersonalInfo.txt04')}}</dt>
          <dd>{{$t('content.signup.popup.PersonalInfoUse.txt05')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt06')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt07')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt08')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt09')}}<br />
          <!-- {{$t('content.signup.popup.PersonalInfoUse.txt10')}}<br /> -->
          {{$t('content.signup.popup.PersonalInfoUse.txt11')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt12')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt13')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt14')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt15')}}<br />
          <!-- {{$t('content.signup.popup.PersonalInfoUse.txt16')}}<br /> -->
          {{$t('content.signup.popup.PersonalInfoUse.txt17')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt18')}}<br />
          </dd>
          <dt> {{$t('content.signup.popup.PersonalInfoUse.txt19')}}</dt>
          <dd>{{$t('content.signup.popup.PersonalInfoUse.txt20')}}<br />
          <!-- {{$t('content.signup.popup.PersonalInfoUse.txt21')}}<br /> -->
          {{$t('content.signup.popup.PersonalInfoUse.txt22')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt23')}}<br />
          <!-- {{$t('content.signup.popup.PersonalInfoUse.txt24')}}<br /> -->
          {{$t('content.signup.popup.PersonalInfoUse.txt25')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt26')}}<br />
          {{$t('content.signup.popup.PersonalInfoUse.txt27')}}<br />
          </dd>
        </dl>
      </div>
    </div>
    <div class="btn_group">
      <button type="button" class="btn btn-ghost" @click.prevent="onAgree(false)">미동의</button>
      <button type="button" class="btn btn-basic" @click.prevent="onAgree(true)">동의</button>
    </div>
  </LayerPopup>
</template>

<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  id: 'PrivacyPopup',
  mixins: [mixinHelperUtils],
  components: {
    LayerPopup: () => import('@/components/base/LayerPopup.vue'),
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
      }),
    },
    allOpen: {
      type: Object,
      default: () => ({
        allOpen: false,
      }),
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onAgree(state) {
      if (state) {
        // true
        this.params.open = false;
        this.$emit('privacyAgree', state);
      } else {
        // false alert
        this.showAlert('미동의시 회원가입 진행이 불가합니다.');
      }
    },
    onClose() {
      this.params.open = false;
      this.allOpen.allOpen = false;
    },
  },
};
</script>

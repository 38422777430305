<template>
  <LayerPopup :popOpen="params.open" :fixedBtn="true" :fullpopup="true" @popClose="onClose" class="layer"
    title="개인정보 취급업무 위탁">
    <div class="terms-container">
      <div class="view-box">
        <dl class="terms">
          <dt>{{$t('content.signup.popup.Management.txt01')}}</dt>
          <dd>{{$t('content.signup.popup.Management.txt02')}}</dd>
          <dd>{{$t('content.signup.popup.Management.txt03')}}<br/>
          </dd>
          <dt>
            <table>
              <colgroup>
                <col width="50%">
                <col width="25%">
                <col width="25%">
              </colgroup>
              <tr>
                <th>{{$t('content.signup.popup.Management.head01')}}</th>
                <th>{{$t('content.signup.popup.Management.head02')}}</th>
                <th>{{$t('content.signup.popup.Management.head03')}}</th>
              </tr>
              <tr>
                <td>{{$t('content.signup.popup.Management.txt04')}}</td>
                <td>{{$t('content.signup.popup.Management.txt05')}}</td>
                <td>{{$t('content.signup.popup.Management.txt06')}}</td>
              </tr>
            </table>
          </dt>
        </dl>
      </div>
    </div>
    <div class="btn_group">
      <button type="button" class="btn btn-ghost" @click.prevent="onAgree(false)">미동의</button>
      <button type="button" class="btn btn-basic" @click.prevent="onAgree(true)">동의</button>
    </div>
  </LayerPopup>
</template>
<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  id: 'managementAgreedPopup',
  mixins: [mixinHelperUtils],
  components: {
    LayerPopup: () => import('@/components/base/LayerPopup.vue'),
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
      }),
    },
    allOpen: {
      type: Object,
      default: () => ({
        allOpen: false,
      }),
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onAgree(state) {
      if (state) {
        // true
        this.params.open = false;
        this.$emit('privacyManagementAgree', state);
      } else {
        // false alert
        this.showAlert('미동의시 회원가입 진행이 불가합니다.');
      }
    },
    onClose() {
      this.params.open = false;
      this.allOpen.allOpen = false;
    },
  },
};
</script>
